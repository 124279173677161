import {catchError, concatMap, first, map, switchMap} from 'rxjs/operators';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AppState} from 'app/reducers';
import {Action, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {User} from '../models/user';
import * as userActions from '../actions/user-actions';
import * as currentUserActions from 'app/actions/current-user-actions';
import {ResetArtifacts} from 'app/actions/artifacts.actions';
import {ResetContacts} from 'app/modules/contacts/actions/contacts.actions';
import {ResetTfa, TfaIsWrong, UserNeedsTfa} from 'app/actions/tfa.actions';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {NaturalPersonActions} from 'app/+store/natural-person';
import {OrganizationActions} from 'app/+store/organization';
import {AngularTokenService, SignInData} from 'angular-token';
import {UserLogoutError, UserLogoutRequest, UserLogoutSuccess} from 'app/actions/user-actions';
import {environment} from 'environments/environment'
import {WebsocketService} from '../services';
import {AuthGuard} from 'app/shared/modules/api/services/auth-guard.service';
import {AutoLogoutService} from 'app/services/auto-logout.service';
import {ProcessTreeService} from '../+store/process-tree/process-tree.service';
import * as store from 'store';
import {TwoFactorAuthService} from '../+store/two-factor-auth/two-factor-auth.service';

@Injectable()
export class UserEffects {
  @Effect()
  userSignIn$: Observable<Action> = this.actions$
    .pipe(
      ofType(userActions.LOGIN_USER_REQUEST),
      switchMap((action: userActions.LoginUserRequest) => {
        const userCredi: SignInData = {
          login: action.payload.email,
          password: action.payload.password
        };
        return this._tokenSvc.signIn(userCredi).pipe(
          map((res: any) => {
            this._store.dispatch(new ResetTfa());

            // Test for TFA requirement and jump into the wizard if this is the case.
            const tfaSetupAccessToken = this.maybeTfaSetupToken(res);
            if (tfaSetupAccessToken) {
              // NOTE: These credentials must be resetted in TFA component as soon as being loaded.
              this.tfaSvc.setCredentials(userCredi.login, userCredi.password, tfaSetupAccessToken.organizationName);

              this._router.navigate([`/session/tfa-setup/${tfaSetupAccessToken.accessToken}`]);
              return new userActions.UserLoginPending(null);
            }

            if (res.body.data) {
              const authData = this._tokenSvc.currentAuthData;
              const response = res.body.data;
              const user = User.buildFrom(response);
              user.accessToken = authData.accessToken;
              user.client = authData.client;

              this._store.dispatch(new NaturalPersonActions.LoadMy());
              this._store.dispatch(new OrganizationActions.LoadAll());
              this._store.dispatch(new currentUserActions.SetCurrentUser(user));
              this._store.dispatch(new ResetTfa());
              this._processTreeService.refresh();

              localStorage.setItem('route', '/dashboard');

              if (user && user.autoLogout) {
                this._autoLogoutSvc.minutesToLogout = user.autoLogout;
              } else {
                this._autoLogoutSvc.minutesToLogout = this._autoLogoutSvc.DEFAULT_LOGOUT_TIMOUT;
              }
              // Kept for debugging.
              // console.error('GetCurrentUser: Auto-Logout timout to: ', this._autoLogoutSvc.minutesToLogout);

              return new userActions.LoginUserSuccess(user);

            } else if (res && res.body && res.body[0].status === 'Code is empty') {
              return new UserNeedsTfa(true);
            } else {
              return new TfaIsWrong(true);
            }
          }),
          catchError((err: HttpErrorResponse) => {
            console.error(err);
            // this._resetToInitialState();
            return of(new userActions.LoginUserError(err.error));
          }),);
      }),
      catchError((err) => {
        console.error(err);
        return of(new userActions.LoginUserError(err.error));
      }),);

  @Effect() userSignUp$: Observable<Action> = this.actions$
    .pipe(
      ofType(userActions.USER_REGISTER_REQUEST),
      map((action: userActions.UserRegisterRequest) => action.payload),
      switchMap(user => {
        const regData: any = user;
        return this._tokenSvc.registerAccount(regData);
      }),
      switchMap(res => {
        // this._router.navigate(['dashboard']);
        const authData = this._tokenSvc.currentAuthData;
        const response = res.json().data;
        const user = User.buildFrom(response);
        user.accessToken = authData.accessToken;
        user.client = authData.client;
        this._store.dispatch(new currentUserActions.SetCurrentUser(user));
        return of(new userActions.UserRegisterSuccess(user));
      }),
      catchError(err => {
        this._resetToInitialState();
        return of(new userActions.UserRegisterError(err.json().errors));
      }),);

  @Effect()
  userSignOut$ = this.actions$.pipe(
    ofType(userActions.USER_LOGOUT_REQUEST),
    concatMap((action: UserLogoutRequest) => {
      return this._tokenSvc.signOut().pipe(
        first(),
        switchMap((res) => {
          if (!environment.production) {
            console.log('UserLogoutRequest: Logging out');
          }
          this._autoLogoutSvc.minutesToLogout = this._autoLogoutSvc.DEFAULT_LOGOUT_TIMOUT;

          this._clearStorage();
          // Kept for debugging
          // console.error('UserLogoutRequest: Auto-Logout set to default to: ', this._autoLogoutSvc.minutesToLogout);
          return [new UserLogoutSuccess()];
        }),
        catchError(error => {
          if (error.status && error.status !== 404) {
            console.error('UserLogoutRequest: Failed logging out or already logged out: Clearing store, navigating to session/sign-in');
          }
          this._store.dispatch({ type: null });
          // if (!/\/session/.test(this._router.url)) {
          if (AuthGuard.isTfaWizardRoute(this._router.url)
            || (!AuthGuard.isSessionRoute(this._router.url)
              && !AuthGuard.isThirdPartyContribution(this._router.url)
              && !AuthGuard.isFileInbox(this._router.url)
              && !AuthGuard.isExternalAccess(this._router.url)
              && !AuthGuard.isCavContribution(this._router.url))) {
            this._router.navigate(['/session/sign-in']);
          } else {
            console.error(`Skipping sign-in navigation for route: ${this._router.url}`)
          }
          this._autoLogoutSvc.minutesToLogout = this._autoLogoutSvc.DEFAULT_LOGOUT_TIMOUT;

          this._clearStorage();
          // Kept for debugging
          // console.error('UserLogoutRequest: Auto-Logout set to default to: ', this._autoLogoutSvc.minutesToLogout);
          return of(new UserLogoutError(error));
        })
      );
    })
  );

  /*
   * Clears the storage but keeps the organization ID so it can be set on next sign-in.
   * Also disconnects open WS connections.
   */
  private _clearStorage() {
    const sidebarResizeSize = store.get('sidebar-resize');
    const org = localStorage.getItem('selectedOrganizationId');
    const excludeClosedStatusSearch = localStorage.getItem('quick-search-exclude-closed');
    localStorage.clear();
    if (org) {
       localStorage.setItem('selectedOrganizationId', org);
     }
     if (excludeClosedStatusSearch) {
      localStorage.setItem('quick-search-exclude-closed', excludeClosedStatusSearch);
    }
     if (sidebarResizeSize) {
      store.set('sidebar-resize', sidebarResizeSize);
     }
    this._store.dispatch({ type: null });
    try {
      this._ws.disconnect();
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Returns a TFA token setup if TFA is required.
   * This setup is for the logged out state and allows to jump into the TFA setup.
   *
   * @param response
   * @private
   */
  private maybeTfaSetupToken(response: any): { accessToken: string, organizationName: string } {
    if (response && response.body && response.body.data && response.body.data.attributes && response.body.data.attributes.access_token) {
      return {
        accessToken: response.body.data.attributes.access_token,
        organizationName: response.body.data.attributes.organization_name
      };
    }
    return null;
  }

  constructor(
    private _router: Router,
    private actions$: Actions,
    private _store: Store<AppState>,
    private _http: HttpClient,
    private tfaSvc: TwoFactorAuthService,
    private _translateSvc: TranslateService,
    private _notifySvc: NotificationService,
    private _tokenSvc: AngularTokenService,
    private _autoLogoutSvc: AutoLogoutService,
    private _processTreeService: ProcessTreeService,
    private _ws: WebsocketService) {
  }

  private _resetToInitialState() {
    this._store.dispatch(new ResetArtifacts());
    this._store.dispatch(new ResetContacts());
    this._store.dispatch(new currentUserActions.ResetCurrentUser());
  }
}
