import { Injectable, NgZone } from '@angular/core';
import * as store from 'store';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {AngularTokenService} from 'angular-token';
import { MatDialog } from '@angular/material/dialog';
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import * as currentUserActions from '../actions/current-user-actions';
import {UserLogoutRequest, UserLogoutSuccess} from '../actions/user-actions';
import {first, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EnvService} from 'app/shared/modules/api-resource/services/env.service';
import {User} from 'app/models/user';
import {AuthGuard} from 'app/shared/modules/api/services/auth-guard.service';

const CHECK_INTERVALL = 1000;         // ms
const STORE_KEY = 'lastActivity';

/**
 * Auto logout service based on local storage for multi tab support,
 * +store and click events on the document.
 * This service is extended by a current user effect listening on each
 * store change to reset the timer.
 * The current URL is saved to returnUrl to allow redirecting to
 * the page of the last work.
 *
 * @author Andreas Baier <andreas.baier@paperbird.org>
 *
 * References:
 * - https://github.com/marcuswestin/store.js
 * - https://itnext.io/inactivity-auto-logout-w-angular-and-ngrx-3bcb2fd7983f
 * - https://medium.com/@sean.nicholas/how-we-implemented-auto-logout-client-side-c060b1eb311c
 */
@Injectable()
export class AutoLogoutService {
  readonly BASE_PATH = 'api/v1/settings/auto_logout_timer';
  debug = false;

  DEFAULT_LOGOUT_TIMOUT = 30;
  public minutesToLogout = this.DEFAULT_LOGOUT_TIMOUT; // minutes
  apiUrl;

  constructor(private _store: Store<AppState>,
              private _zone: NgZone,
              private _tokenSvc: AngularTokenService,
              private _router: Router,
              private _route: ActivatedRoute,
              private _dialogRef: MatDialog,
              private _http: HttpClient,
              private env: EnvService
  ) {
    this.check();
    this.initListener();
    this.initInterval();
    this.apiUrl = env.apiBase();
  }

  get lastAction() {
    return parseInt(store.get(STORE_KEY), 10);
  }
  set lastAction(value) {
    store.set(STORE_KEY, value);
  }

  update(autoLogout: number) {
    const params = { data: { attributes: { auto_logout: autoLogout } } };
    return this._http
      .put(`${this.apiUrl}/${this.BASE_PATH}`, params).pipe(
        map((res) => {
          return User.buildFrom(res);
        })
      );
  }

  initListener() {
    this._zone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval() {
    this._zone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    })
  }

  reset() {
    // TODO: Remove: Just kept for debug.
    // const state = this._router.url;
    // console.error(`Resetting now Autologout Timer. Route: ${state}`);
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + this.minutesToLogout * 60 * 1000;
    const diff = timeleft - now;
    if (this.debug) {
      console.error(`Checking for automatic logout: ${diff} / signed in: ${this._tokenSvc.userSignedIn()}`);
    }
    const isTimeout = diff < 0;
    const state = this._router.url;

    this._zone.run(() => {
      if (isTimeout && this._tokenSvc.userSignedIn()) {
        console.error(`Automatic logout after ${this.minutesToLogout} minutes of inactivity. Route: ${state}`);
        try {
          this._dialogRef.closeAll();
        } catch (err) {
          console.error('Attempt to close all open dialogs failed:', err);
        }
        this._tokenSvc.signOut().pipe(
          first()
        ).subscribe(res => {
          this._store.dispatch(new UserLogoutSuccess());
          this._store.dispatch({ type: null });
          this._navigateToSignIn(state);
        }, (err) => {
          console.error(err);
          this._store.dispatch({ type: null });
          this._navigateToSignIn(state);
        });
      }
    });
  }

  private _navigateToSignIn(state: string) {
    if (!AuthGuard.isSessionRoute(state)
        && !AuthGuard.isThirdPartyContribution(state)
        && !AuthGuard.isFileInbox(state)
        && !AuthGuard.isExternalAccess(state)
        && !AuthGuard.isCavContribution(state)) {
      this._router.navigate(['/session/sign-in'],
        {queryParams: {returnUrl: state}});
    } else {
      console.error(`Skipping sign-in navigation for route: ${state}`)
    }
    // Reset the state
    this._store.dispatch(new currentUserActions.ResetCurrentUser());
    this._store.dispatch({ type: null });

    const sidebarResizeSize = store.get('sidebar-resize');
    const org = localStorage.getItem('selectedOrganizationId');
    const excludeClosedStatusSearch = localStorage.getItem('quick-search-exclude-closed');
    localStorage.clear();
    if (org) {
       localStorage.setItem('selectedOrganizationId', org);
     }
     if (excludeClosedStatusSearch) {
      localStorage.setItem('quick-search-exclude-closed', excludeClosedStatusSearch);
    }
     if (sidebarResizeSize) {
      store.set('sidebar-resize', sidebarResizeSize);
     }
  }
}

